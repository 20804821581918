<template>
  <div>
    <h2 class="font-weight-bolder mb-2">
      <span @click="$router.go(-1)">
        <b-button
          variant="white"
          class="btn-icon rounded-circle mr-50 text-danger bg-white"
          size="sm"
        >
          <feather-icon icon="ChevronLeftIcon" />
        </b-button>
      </span>
      Crear trabajo
    </h2>
    <b-card>
      <!-- form -->
      <validation-observer ref="createJob">
        <b-form @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group :label="'Nombre'" label-for="job-name">
                  <b-form-input
                    v-model="name"
                    name="name"
                    :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Estado'"
                rules="required"
              >
                <b-form-group :label="'Estado'" label-for="job-status">
                  <v-select
                    v-model="status"
                    :filterable="false"
                    :searchable="false"
                    :options="selectStatus"
                    :placeholder="'Estado'"
                  >
                    <template slot="option" slot-scope="option">
                      {{ statusJobsText[option.label] }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ statusJobsText[option.label] }}
                    </template>
                  </v-select>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Obra'"
                rules="required"
              >
                <b-form-group :label="'Obra'" label-for="job-project">
                  <v-select
                    v-model="project"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectProjects"
                    :placeholder="'Obra'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Departamento'"
                rules="required"
              >
                <b-form-group
                  :label="'Departamento'"
                  label-for="job-department"
                >
                  <v-select
                    v-model="department"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectDepartments"
                    :placeholder="'Departamento'"
                    :disabled="!project"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Categoría'"
                rules="required"
              >
                <b-form-group :label="'Categoría'" label-for="job-category">
                  <v-select
                    v-model="category"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="totalCategories"
                    :placeholder="'Categoría'"
                    :disabled="readOnlyCat"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider #default="{ errors }" :name="'Subcategoría'">
                <b-form-group
                  :label="'Subcategoría'"
                  label-for="job-subcategory"
                >
                  <v-select
                    v-model="subcategory"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="totalSubcategories"
                    :placeholder="'Subcategoría'"
                    :disabled="readOnlySubCat"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Fecha de inicio'"
                rules="required"
              >
                <b-form-group
                  :label="'Fecha de inicio'"
                  label-for="job-fechaini"
                >
                  <flat-pickr
                    v-model="date_ini"
                    name="date_ini"
                    class="form-control"
                    placeholder="dd/mm/YYYY"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="'Fecha de fin'" label-for="job-fechafin">
                <flat-pickr
                  v-model="date_fin"
                  name="date_fin"
                  class="form-control"
                  placeholder="dd/mm/YYYY"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group :label="'Descripción'" label-for="job-description">
                <quill-editor v-model="description" />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group :label="'Documentos'" label-for="job-documents">
                <ImageDropzone ref="documents" :files="files" maxFiles="10" />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                {{ "Enviar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {
  FlatPickrToTimestamp,
  TimestampToFlatPickrWithTime,
} from "@/libs/helpers";
import flatPickr from "vue-flatpickr-component";
import { config } from "@/shared/app.config";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    flatPickr,
    ImageDropzone,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusJobsVariant: config.statusJobsVariants,
      statusJobsText: config.statusJobsText,
      required,
      email,
      searchTerm: "",
      name: "",
      description: "",
      date_ini: "",
      date_fin: "",
      department: "",
      category: "",
      subcategory: "",
      status: "",
      project: "",
      files: [],
      totalEstructura: [],
      totalCategories: [],
      totalSubcategories: [],
    };
  },
  watch: {
    department: function (n, o) {
      if (n !== o) {
        this.category = "";
        this.subcategory = "";
        this.totalEstructura = [];
        this.totalCategories = [];
        this.totalSubcategories = [];
        this.chargeCategories();
      }
    },
    category: function (n, o) {
      if (n !== o) {
        this.subcategory = "";
      }
    },
    project: async function (n, o) {
      this.department = "";
      this.category = "";
      this.subcategory = "";
      this.date_ini = "";
      this.date_fin = "";
      if (n !== o) {
        if (this.project) {
          console.log(this.project.id);
          await this.getSelectDepartments({ project_id: this.project.id });
          if (this.project.date_ini) {
            this.date_ini = TimestampToFlatPickrWithTime(this.project.date_ini);
          }
          if (this.project.date_fin) {
            this.date_fin = TimestampToFlatPickrWithTime(this.project.date_fin);
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      selectStatus: "jobs/getSelectStatus",
      selectProjects: "projects/getSelectProjects",
      selectCategories: "categories/getItemsCreateProject",
      selectDepartments: "departments/getSelectDepartments",
      projectParam: "projects/getProject",
    }),
    readOnlyCat() {
      if (this.department) {
        return false;
      }
      return true;
    },
    readOnlySubCat() {
      if (this.category) {
        if (
          this.totalEstructura[this.category] &&
          this.totalEstructura[this.category].childrens
        ) {
          this.totalSubcategories =
            this.totalEstructura[this.category].childrens;
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      create: "jobs/create",
      getSelectStatus: "jobs/getSelectStatus",
      getSelectProjects: "projects/getSelectProjects",
      getSelectCategories: "categories/getListCategoriesCreateProject",
      getSelectDepartments: "departments/selectDepartments",
      getProject: "projects/getProject",
    }),
    handleSubmit() {
      this.$refs.createJob.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.create({ task: formData });
          }
        }
      });
    },
    createFormData() {
      const data = this.$refs.documents.getFormData("documents");
      data.append("date_ini", FlatPickrToTimestamp(this.date_ini));
      if (this.date_fin) {
        data.append("date_fin", FlatPickrToTimestamp(this.date_fin));
      } else {
        data.append("date_fin", null);
      }
      data.append("name", this.name);
      data.append("project_id", this.project.id);
      data.append("status", this.status);
      data.append("description", this.description);

      console.log("SELCCIONADO");

      if (this.totalEstructura[this.category].childrens) {
        console.log("tiene childrens. SUBCAT: ", this.subcategory.name);

        this.totalEstructura[this.category].childrens.forEach((element) => {
          if (element.name == this.subcategory.name) {
            console.log("category_id", element.id);
            data.append("category_id", element.id);
          }
        });
      } else {
        console.log("NO tiene childrens");
        console.log("category_id", this.totalEstructura[this.category].id);
        data.append("category_id", this.totalEstructura[this.category].id);
      }

      return data;
    },
    setProject() {
      if (this.$route.params.project_param) {
        this.getProject(this.$route.params.project_param).then(() => {
          this.project = this.projectParam;
        });
      }
    },
    chargeCategories() {
      const department = this.department ? this.department.id : "";
      if (department != "") {
        this.getSelectCategories({ departments: [department] }).then(() => {
          setTimeout(() => {
            /*             console.log("CAT:: ", this.selectCategories);
            console.log("DEPATMENT:: ", this.department.name); */
            console.log(
              "CATEGORIAS:: ",
              this.selectCategories[this.department.name]
            );

            this.totalEstructura = this.selectCategories[this.department.name];
            this.totalCategories = Object.keys(
              this.selectCategories[this.department.name]
            );
          }, 1000);
        });
      }
    },
  },
  async created() {
    await this.getSelectStatus();
    await this.getSelectProjects({});

    await this.chargeCategories();
    await this.setProject();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
