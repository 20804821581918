var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"font-weight-bolder mb-2"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",attrs:{"variant":"white","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}})],1)],1),_vm._v(" Crear trabajo ")]),_c('b-card',[_c('validation-observer',{ref:"createJob"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Nombre',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Nombre',"label-for":"job-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":'Nombre'},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Estado',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Estado',"label-for":"job-status"}},[_c('v-select',{attrs:{"filterable":false,"searchable":false,"options":_vm.selectStatus,"placeholder":'Estado'},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.statusJobsText[option.label])+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.statusJobsText[option.label])+" ")]}}],null,true),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Obra',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Obra',"label-for":"job-project"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectProjects,"placeholder":'Obra'},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Departamento',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Departamento',"label-for":"job-department"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectDepartments,"placeholder":'Departamento',"disabled":!_vm.project},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Categoría',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Categoría',"label-for":"job-category"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.totalCategories,"placeholder":'Categoría',"disabled":_vm.readOnlyCat},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Subcategoría'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Subcategoría',"label-for":"job-subcategory"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.totalSubcategories,"placeholder":'Subcategoría',"disabled":_vm.readOnlySubCat},model:{value:(_vm.subcategory),callback:function ($$v) {_vm.subcategory=$$v},expression:"subcategory"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Fecha de inicio',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Fecha de inicio',"label-for":"job-fechaini"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"date_ini","placeholder":"dd/mm/YYYY"},model:{value:(_vm.date_ini),callback:function ($$v) {_vm.date_ini=$$v},expression:"date_ini"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Fecha de fin',"label-for":"job-fechafin"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"date_fin","placeholder":"dd/mm/YYYY"},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":'Descripción',"label-for":"job-description"}},[_c('quill-editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":'Documentos',"label-for":"job-documents"}},[_c('ImageDropzone',{ref:"documents",attrs:{"files":_vm.files,"maxFiles":"10"}})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s("Enviar")+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }